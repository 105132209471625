import React from "react"
import PropTypes from "prop-types"
import Navbar from "./Global/NavbarMenu"
import Footer from "./Global/Footer"

import "./bootstrap.min.css"
import "./layout.css"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]', {
    offset: 30,
  })
}

const Layout = ({ children, location }) => {
  return (
    <>
      <Navbar location={location}/>
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
