import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"
import logoWhite from "../../images/gh-logo-white.png"

const links = [
  {
    id: 1,
    path: "/",
    text: "Home",
  },
  {
    id: 2,
    path: "/our-story",
    text: "Our Story",
  },
  {
    id: 3,
    path: "/services",
    text: "Services",
  },
  {
    id: 4,
    path: "/showcase",
    text: "Showcase",
  },
  {
    id: 5,
    path: "/wordpress-security",
    text: "WP Security",
  },
  {
    id: 6,
    path: "/contact",
    text: "Contact",
  },
]

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

export default function Navbar({ location }) {
  const [navStatus, setNavStatus] = useState("bg-transparent navbar-dark")
  const [css, setCss] = useState("collapse navbar-collapse")
  const [screenWidth, setScreenWidth] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [navbarOpen, setNavBarOpen] = useState(false)

  useEffect(() => {
    // hide clicking on the same link
    setDropdownOpen(false)
  }, [location])

  useEffect(() => {
    if (isBrowser) {
      const scrollListener = () => {
        const navbarHeight = 56 // Bootstrap default
        const startShow = navbarHeight * 2 // screenHeight - navbarHeight;
        const scrollY = window.scrollY

        if (scrollY > startShow || navbarOpen) {
          setNavStatus("navbar-custom navbar-dark")
        } else {
          setNavStatus("bg-transparent navbar-dark")
        }
        if (scrollY !== 0 && window.innerWidth > 575) {
          setDropdownOpen(false)
        }
      }

      const updateScreenSize = () => {
        setScreenWidth(window.innerWidth)
      }

      updateScreenSize()
      window.addEventListener("scroll", scrollListener)
      window.addEventListener("resize", updateScreenSize)
      return () => {
        window.removeEventListener("scroll", scrollListener)
        window.removeEventListener("resize", updateScreenSize)
      }
    }
  }, [navbarOpen])

  const navbarHandler = () => {
    setNavBarOpen(!navbarOpen)
    setDropdownOpen(!navbarOpen)

    if (navbarOpen) {
      setCss("collapse navbar-collapse")
      setNavStatus("bg-transparent navbar-dark")
    } else {
      setCss("collapse navbar-collapse show")
      setNavStatus("navbar-custom navbar-dark")
    }
  }

  const onToggleSubMenu = () => {
    if (screenWidth > 575) {
      setDropdownOpen(!dropdownOpen)
      setNavStatus("navbar-custom navbar-dark")
    } else {
      navigate("/services/website-development")
      setCss("collapse navbar-collapse")
    }
  }

  return (
    <nav className={`navbar navbar-expand-sm fixed-top ${navStatus}`}>
      <Link to="/" className="navbar-brand">
        <img src={logoWhite} style={{ width: 120 }} alt="gianthouse logo" />
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        onClick={() => navbarHandler()}
        aria-label="Toggle navigation bar"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className={css}>
        <ul className="navbar-nav ml-auto">
          {links.map((link) => {
            return (
              <li key={link.id} className="nav-item">
                {link.text === "Services" ? (
                  <>
                    <button
                      type="button"
                      onClick={() => onToggleSubMenu()}
                      aria-label="Toggle dropdown"
                    >
                      <span className={`custom-nav-link text-capitalize`}>
                        {link.text}
                      </span>
                    </button>

                    <ul
                      className={`nav-dropdown ${
                        dropdownOpen ? "visible" : ""
                      } ${screenWidth < 575 ? "relative" : "absolute"}`}
                    >
                      <li>
                        <Link to={`${link.path}/website-development`}>
                          Website Development
                        </Link>
                      </li>
                      <li>
                        <Link to={`${link.path}/ecommerce`}>Ecommerce</Link>
                      </li>
                      <li>
                        <Link to={`${link.path}/mobile-web-app`}>
                          Mobile and Web App
                        </Link>
                      </li>
                      <li>
                        <Link to={`${link.path}/blockchain`}>Blockchain</Link>
                      </li>
                    </ul>
                  </>
                ) : (
                  <Link
                    to={link.path}
                    className={`custom-nav-link text-capitalize`}
                  >
                    {link.text}
                  </Link>
                )}
              </li>
            )
          })}
        </ul>
      </div>
    </nav>
  )
}
